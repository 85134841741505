<template>
  <section class="bg-white rounded px-2">
    <div v-if="!loading && post.post_date" class="row mx-0 py-2 py-4">
      <h1 class="text-center mb-3 mb-lg-5"> {{ post.post_title }} </h1>
      <div class="col-12 px-0" v-html="post.post_content"></div>
      <div class="row mx-0">
        <div class="col-auto px-0 ms-auto">
          <small>por:</small><span class="font-italic"> {{ post.ref_users.display_name }} </span>
          <small>em: {{ post.post_date.slice(0,-3) }} </small> </div>
      </div>
    </div>
    <b-icon v-else icon="circle-fill" animation="throb" font-scale="4" class="w-100 my-3 my-lg-5 mx-auto" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        loading: true,
        post: {}
      }
    },
    beforeMount() {
      if (this.$route.query.p) this.getPost();
      else this.$router.push('/news/inicio')
    },
    methods: {
      getPost() {
        this.loading = true
        this.$store.dispatch('getPost', this.$route.query.p)
          .then((res) => this.post = res)
          .catch((error) => [this.swal(false, error.data.mensagem, error), this.$router.push('/news/inicio')])
          .finally(() => this.loading = false)
      },
      fixLink() {
        Array.from(document.querySelectorAll('#content a')).map(item => item.setAttribute('target', '_blank'))
      }
    }
  }
</script>
<style>
  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
  img {
    max-width: 100%;
  }
</style>
